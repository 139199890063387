
<template>
  <v-scale-transition>
    <wide-panel
      :title="title"
      :breadcrumbs="breadcrumbs"
    >
      <v-toolbar
        flat
      >
        <v-flex xs5>
          <v-select
            v-model="select"
            :items="selects"
            item-text="text"
            item-value="value"
            label="Filter By"
          />
        </v-flex>
        <v-menu
          v-if="select === 'user'"
          v-model="startMenu"
          lazy
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="start"
            label="Select start date"
            prepend-icon="mdi-calendar-outline"
            readonly
          />
          <v-date-picker
            v-model="start"
            min="2020-11-01"
            :max="today"
            no-title
            @input="startMenu = false"
          />
        </v-menu>
        <v-menu
          v-if="select === 'user'"
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="end"
            label="Select end date"
            prepend-icon="mdi-calendar-outline"
            readonly
          />
          <v-date-picker
            v-model="end"
            min="2020-11-01"
            :max="today"
            no-title
            @input="startMenu = false"
          />
        </v-menu>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="green"
              v-on="on"
              @click="exportReport"
            >
              <v-icon dark>
                mdi-microsoft-excel
              </v-icon>
            </v-btn>
          </template>
          <span>
            Export Report
          </span>
        </v-tooltip>

        <v-flex xs1>
          <v-checkbox
            v-model="xox"
            label="XOX"
          />
        </v-flex>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :expand="true"
            :total-items="totalItems"
            :rows-per-page-items="[10, 25, 50, 100]"
            class="px-1 pb-1"
          >
            <template #items="props">
              <tr @click="props.expanded = !props.expanded">
                <td
                  v-if="props.item.name"
                  class="text-xs-left"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.nric"
                  class="text-xs-left"
                >
                  {{ props.item.nric }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.msisdn"
                  class="text-xs-left"
                >
                  {{ props.item.msisdn }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.subscriptionDate"
                  class="text-xs-left"
                >
                  {{ props.item.subscriptionDate }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.status"
                  class="text-xs-left"
                >
                  {{ props.item.status }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.dealer"
                  class="text-xs-left"
                >
                  {{ props.item.dealer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.rank"
                  class="text-xs-left"
                >
                  {{ props.item.rank }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.dealershipDate"
                  class="text-xs-left"
                >
                  {{ props.item.dealershipDate }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.introducerId"
                  class="text-xs-left"
                >
                  {{ props.item.introducerId }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'
import security from '@/security'
import { DateTime } from 'luxon'
import zipcelx from 'zipcelx'
// import Jimp from 'jimp'

const pageTitle = 'Active Record'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      select: 'dealer',
      startMenu: false,
      endMenu: false,
      activeItem: null,
      alert: false,
      alertType: null,
      alertMessage: null,
      xox: null,
      error: null,
      start: null,
      end: null,
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      maxDate: '',
      selects: [
        {
          text: 'Dealer',
          value: 'dealer',
        },
        {
          text: 'User',
          value: 'user',
        },
      ],
      headers: [
        {
          align: 'left',
          sortable: false,
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: false,
          text: 'NRIC',
          value: 'nric',
        },
        {
          align: 'left',
          sortable: false,
          text: 'MSISDN',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Subscription',
          value: 'subscriptionDate',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Dealer Id',
          value: 'dealer',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Rank',
          value: 'rank',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Dealership',
          value: 'dealershipDate',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Introducer',
          value: 'introducerId',
        },
      ],
      exportReportHeader:
      [
        {
          value: 'Name',
          type: 'string',
        },
        {
          value: 'Nric',
          type: 'string',
        },
        {
          value: 'Msisdn',
          type: 'string',
        },
        {
          value: 'Plan',
          type: 'string',
        },
        {
          value: 'Subscription Date',
          type: 'string',
        },
        {
          value: 'Status',
          type: 'string',
        },
        {
          value: 'Dealer',
          type: 'string',
        },
        {
          value: 'Rank',
          type: 'string',
        },
        {
          value: 'Dealership',
          type: 'string',
        },
        {
          value: 'Introducer',
          type: 'string',
        },
        {
          value: 'Area Dealer',
          type: 'string',
        },
        {
          value: 'Master Dealer',
          type: 'string',
        },
        {
          value: 'Address',
          type: 'string',
        },
        {
          value: 'City',
          type: 'string',
        },
        {
          value: 'Postal Code',
          type: 'string',
        },
        {
          value: 'State',
          type: 'string',
        },
        {
          value: 'Race',
          type: 'string',
        },
        {
          value: 'Profession',
          type: 'string',
        },
        {
          value: 'DOB',
          type: 'string',
        },
        {
          value: 'Working Type',
          type: 'string',
        },
      ],
      items: [],
      onex: true,
      loading: false,
      snackbar: false,
      snackbarMessage: null,
      search: null,
      searchId: null,
      source: null,
      title: pageTitle,
      totalItems: 0,
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    end: function (val) {
      this.getActiveRecord(val)
    },
    start: function (val) {
      this.getActiveRecord(val)
    },
    select: function (val) {
      this.getActiveRecord(val)
    },
    xox: function (val) {
      this.getActiveRecord(val)
    },
  },
  mounted: function () {
    this.xox = security.me.claimer() === 'xox'
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.start = now.toFormat('yyyy-LL-dd')
    this.maxDate = this.end = now.toFormat('yyyy-LL-dd')
  },
  methods: {
    async getActiveRecord () {
      this.loading = true
      const params = createGetParams({
        select: this.select,
        start: this.start,
        end: this.end,
        xox: this.xox,
      })
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(params, { cancelToken: this.source.token })
        const response = await this.$rest.get('getActiveRecord.php', params)
        this.items = response.data.items
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.hint = error.message
        }
        this.items = []
      } finally {
        this.loading = false
      }
    },
    exportReport: function () {
      const config = {
        filename: 'ActiveRecord' + '-' + this.select + '-' + this.today,
        sheet: {
          data: [],
        },
      }
      config.sheet.data.push(this.exportReportHeader)
      this.items.forEach(item => {
        const transaction = [
          {
            value: item.name,
            type: 'string',
          },
          {
            value: item.nric,
            type: 'string',
          },
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.plan,
            type: 'string',
          },
          {
            value: item.subscriptionDate,
            type: 'string',
          },
          {
            value: item.status,
            type: 'string',
          },
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.rank,
            type: 'string',
          },
          {
            value: item.dealershipDate,
            type: 'string',
          },
          {
            value: item.introducerId,
            type: 'string',
          },
          {
            value: item.areaDealer,
            type: 'string',
          },
          {
            value: item.masterDealer,
            type: 'string',
          },
          {
            value: item.address,
            type: 'string',
          },
          {
            value: item.city,
            type: 'string',
          },
          {
            value: item.postalCode,
            type: 'string',
          },
          {
            value: item.state,
            type: 'string',
          },
          {
            value: item.race,
            type: 'string',
          },
          {
            value: item.profession,
            type: 'string',
          },
          {
            value: item.dob,
            type: 'string',
          },
          {
            value: item.workingType,
            type: 'string',
          },
        ]
        config.sheet.data.push(transaction)
      })
      zipcelx(config)
    },

    cancelRequest: function () {
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    refreshList () {
      this.getActiveRecord().then(data => {
        this.items = data.items
        this.totalItems = data.totalItems
      })
    },
    resetList () {
      this.items = []
      this.totalItems = 0
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
